<template>
  <div id="login" v-loading="isLoading">
    <div class="GG_border">
      <p class="title">Sign in</p>
		<div class="user-input-case input-case">
			<el-input
			  placeholder="Email"
			  v-model="param.username"
			  clearable
			/>
			<img src="@/assets/static_client/user_icon.png" alt="no" />
		</div>
		
      <div class="lock-input-case input-case">
			<el-input
			 class="password-input"
			  placeholder="Password"
			  type="password"
			  clearable
			  v-model="param.password"
			/>
			<img src="@/assets/static_client/lock_icon.png" alt="no" />
      </div>

      <button @click="getaccount">Sign in</button>
		
		<div class="forgot-case" @click="navigateToChangePassword">Forgot your password?</div>
		
		<div class="no-yet" @click="navigateToCreateAccount">No account yet?create an account</div>
		
		<div class="email">Contact email:info1@skyspfdecor.com</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { sharedState } from '@/store_ob/ob.js';

export default {
  name: "login",
  data() {
    return {
	   GETURL:sharedState.goalUrl,
      isCheck: false, //是否记住账号
      param: {
        username: "", //登录账号
        password: "", //登录密码
      },
	  isLoading:false
    };
  },
  created() {
	 const expirationTime = localStorage.getItem('userExpiration');
	 if (expirationTime && Date.now() > expirationTime) {
	    // 数据已过期，清除 localStorage 中的数据
	    localStorage.removeItem('user');
	    localStorage.removeItem('userExpiration');
	 } else {
	    // 数据未过期，如果用户访问登录页面，将其重定向到首页
	    if (this.$route.name === 'login' && localStorage.getItem('user')) {
		     this.$router.push({ name: 'finance' });
	    }
	 }
  },
  mounted() {
	  if(localStorage.getItem('user_last_login')){
		  this.param.username = localStorage.getItem('user_last_login');
	  }
	  if (this.$route.query.email) {
	    this.param.username = this.$route.query.email;
	  }
  },
  methods: {
	  async getaccount() {
			this.isLoading = true;  // 在发送请求前设置为 true 来显示加载指示器
			try {
			  const response = await axios.post(this.GETURL + '/api/index/login', {
				 username: this.param.username,
				 password: this.param.password 
			  });
			  
			  if (response.data.success) {
			    const expirationTime = 5 * 60 * 60 * 1000; // 2小时，单位毫秒
			    localStorage.setItem('userExpiration', Date.now() + expirationTime);
				 localStorage.setItem('user', this.param.username);
				 localStorage.setItem('token', response.data.token);
				 localStorage.setItem('personName', response.data.personName);
				 localStorage.setItem('personId', response.data.userId);
				 localStorage.setItem('roleType', response.data.roleType);
				 console.log('token=',response.data.token);
				 this.$router.push({ name: 'product' });
			  } else {
				 this.$message({ message: 'Login failed：' + response.data.message, type: 'error' });
			  }
			} catch (error) {
			    this.$message({ message: 'Login failed, please try again later', type: 'error' });
			} finally {
			    this.isLoading = false; 
			}
	  },
	  
	  navigateToCreateAccount() {
	      this.$router.push({ name: 'create' }); // Use the route name of your create.vue page
	  },
	  
	  navigateToChangePassword(){
		  this.$router.push({ name: 'changePassword' }); 
	  }
  },
};
</script>

<style src="./login.scss" scoped lang="scss"></style>
<style>
	
	.GG_border .el-input__icon {
       font-size: 25px;  /* 调整为你想要的图标大小 */
    }
	 
	 .GG_border .el-input__inner{
		 height: 60px;
		 padding-left: 60px;
		 padding-right: 80px;
		 box-sizing: border-box;
		 font-size: 20px;
		 line-height: 20px;
		 font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
	 }
	
	 .GG_border .el-input .el-input__suffix {
		 width:80px;
	 }
	
</style>
