import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
     token: '',
	  checkoutData: [],
	  orderData:[],
	  personName: '',  
	  personAddress: '',
  },
  mutations: {
    setToken(state, token) {
        state.token = token;
    },
	 setCheckoutData(state, data) {
		 state.checkoutData = data;
	 },
	 setOrderData(state, data) {
	 	 state.orderData = data;
	 },
	 setPersonName(state, name) {  
	    state.personName = name;
	 },
	 setPersonAddress(state, address) {
	    state.personAddress = address;
	 }
  },
  actions: {

  },
  modules: {
    // configData
  }
})
