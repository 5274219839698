<template>
  <div id="app">
    <div class="layout">
     <Layout>

        <Layout>
          <Sider hide-trigger class="left-layout" width="220">
			 <Menu ref="side_menu" width="220" :open-names="leftOpenName" :active-name="leftActive" @on-select="showSubmenu" @on-open-change="showSubOpenmenu" style="background: #3662EC;overflow: hidden;" >
			   <div class="logo-txt">SkySPF</div> 
				<div v-for="(list1,Pindex) in dynamicMenu" :key="Pindex">
				  <Submenu v-if="list1.children&&list1.children.length!==0" :name="list1.c_name" >
					 <template slot="title">
						<img :src="list1.icon" alt="no" class="img-icon889" :style="{ opacity: isSubmenuTitleSelected(list1) ? 1 : 0.5 }" />
						<span :class="{'submenu-selected': isSubmenuTitleSelected(list1)}">{{list1.nav_name}}</span>
					 </template>
					 <menu-item :class="{'sub-menu-item-selected': isSelected(list2.c_name)}" :name="list2.c_name" v-for="list2 in list1.children" :to="list2.c_name" :key="list2.id">
						<span >{{list2.nav_name}}</span>
					 </menu-item>
				  </Submenu>
				  <MenuItem :class="{'menu-item-selected': isSelected(list1.c_name)}" class="noChildmenuitem" v-else :name="list1.c_name" :to="list1.c_name">
					 <img :src="list1.icon" alt="no" class="img-icon889" :style="{ opacity: isSelected(list1.c_name) ? 1 : 0.5 }" />
					 <span>{{ list1.nav_name }}</span>
				  </MenuItem>
				</div>
			 </Menu>					 
          </Sider>
		  <Layout style="height: 100vh; overflow-y: auto;">
			  <div class="dropdown-tab">
				  <div class="role-class">role: <span>{{ roleDisplayName }}</span></div>
				  <el-dropdown trigger="click" @visible-change="handleDropdownVisibilityChange" :class="{ 'is-open': isOpen,'dropdownclass': 1 == 1}">
					<div class="flex-y-center">
					  <span>{{ userName }}</span>
					  <i class="el-icon-arrow-down el-icon--right"></i>
					</div>
					<el-dropdown-menu slot="dropdown">
					  <el-dropdown-item  @click.native="log_out">
						Log out
					  </el-dropdown-item>
					</el-dropdown-menu>
				  </el-dropdown>
			   </div>
					
			  <Layout :style="{padding: '10px 20px 10px 20px'}">
				<Content>
				  <router-view />
				</Content>
			  </Layout>
		  </Layout>	 
			 
        </Layout>
		  
		  
     </Layout>
    </div>
	 
  </div>
</template>
<script>

// import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      printData: {},
      orderNumber: '',
      shopYysj: '',
      headLogo: '',
      portrait: '',
      loginType: '',
      copy: '',
      menuCurrent: '/',
      leftActive: '1',
      leftOpenName: ['1'],
      leftMenuCeshi: [
            {
              id: '1',
              nav_name: 'Order the product',
              c_name: 'product',
              icon: require('@/assets/static/product.png'),
            },
				{
				  id: '2',
				  nav_name: 'Unsubmitted Jobs',
				  c_name: 'shopping_cart',
				  icon: require('@/assets/static/shopping_cart.png'),
				},
				{
				  id: '3',
				  nav_name: 'Submitted Jobs',
				  c_name: 'order',
				  icon: require('@/assets/static/order.png'),
				},
				{
				  id: '4',
				  nav_name: 'Information',
				  c_name: 'personal_info',
				  icon: require('@/assets/static/person.png'),
				},
				{
				  id: '5',
				  nav_name: 'Address Management',
				  c_name: 'address',
				  icon: require('@/assets/static/address.png'),
				},
				{
				  id: '6',
				  nav_name: 'Learning Center',
				  c_name: 'learning_center',
				  icon: require('@/assets/static/xuexi.png'),
				},
				{
				  id: '7',
				  nav_name: 'Contact Us',
				  c_name: 'contact',
				  icon: require('@/assets/static/lianxi.png'),
				}
								
          ],
			 
      leftMenuCeshiAgent: [
            {
              id: '1',
              nav_name: 'Order the product',
              c_name: 'product',
              icon: require('@/assets/static/product.png'),
            },
				{
				  id: '2',
				  nav_name: 'Unsubmitted Jobs',
				  c_name: 'shopping_cart',
				  icon: require('@/assets/static/shopping_cart.png'),
				},
				{
				  id: '3',
				  nav_name: 'Submitted Jobs',
				  c_name: 'order',
				  icon: require('@/assets/static/order.png'),
				},
				{
				  id: '4',
				  nav_name: 'Sub-Orders',
				  c_name: 'sub_agent_orders',
				  icon: require('@/assets/static/sub_agent.png'),
				},				
				{
				  id: '5',
				  nav_name: 'Information',
				  c_name: 'personal_info',
				  icon: require('@/assets/static/person.png'),
				},
				{
				  id: '6',
				  nav_name: 'Address Management',
				  c_name: 'address',
				  icon: require('@/assets/static/address.png'),
				},
				{
				  id: '7',
				  nav_name: 'Learning Center',
				  c_name: 'learning_center',
				  icon: require('@/assets/static/xuexi.png'),
				},
				{
				  id: '8',
				  nav_name: 'Contact Us',
				  c_name: 'contact',
				  icon: require('@/assets/static/lianxi.png'),
				}
								
          ],			 
			 
			 
		 isOpen: false,

    };
  },
  created() {
	const expirationTime = localStorage.getItem('userExpiration');
		 if (!expirationTime  || !localStorage.getItem('user') || expirationTime < Date.now()) {
          this.$router.push({ name: 'login' });
		 } 
  },
  computed: {
	  userName(){
		  return localStorage.getItem('personName');
	  },
	  roleType(){
		  return localStorage.getItem('roleType');
	  },
	  
		dynamicMenu() {
		  let menu = this.roleType === 'dealer' ? this.leftMenuCeshi : this.leftMenuCeshiAgent;
		  if (this.roleType === 'others') {
			 menu = menu.map(item => {
				if (item.id === '4') {
				  return {
					 ...item,
					 nav_name: 'Sub-orders', // Change the nav_name for the specific item
				  };
				}
				return item;
			 });
		  }
		  return menu;
		},

	 roleDisplayName() {
		let displayName = '';
		switch(this.roleType) {
		  case 'agent':
			 displayName = 'Sale-Rep';
			 break;
		  case 'dealer':
			 displayName = 'Dealer';
			 break;
		  default:
			 displayName = this.roleType.charAt(0).toUpperCase() + this.roleType.slice(1); // Default to capitalize the first letter
		}
		return displayName;
	 }
  },
  watch: {

  },
  methods: {
    handleDropdownVisibilityChange(visible) {
      this.isOpen = visible;
    },

    showSubOpenmenu(name) {
      console.log('showSubOpenmenu_name=',name);
      sessionStorage.setItem('leftOpenName', JSON.stringify(name));
      this.leftOpenName = name;
	  if (Array.isArray(name) && name.length > 0) {
		  this.leftActive = name[name.length - 1];
		  this.$router.push({name:this.leftActive});
	  }
    },
    showSubmenu(name) {
      console.log('this.showSubmenu_name=',name);
      sessionStorage.setItem('leftActive', name);
      this.leftActive = name;

    },
	isSelected(name) {
		 return this.leftActive === name;
	 },
	 isSubmenuTitleSelected(list1) {
	     return list1.children.some(child => child.c_name === this.leftActive);
	  },
	  
   log_out(){
		localStorage.setItem('user_last_login', localStorage.getItem('user'));
		localStorage.removeItem('user');
		localStorage.removeItem('userExpiration');
		this.$router.push({ name: 'login' });
	},
	
	

  },
  destroyed(){
    console.log('页面销毁')
    
  },
};
</script>
<style lang="scss" scoped>
	.el-dropdown.is-open .el-icon-arrow-down {
	  transform: rotate(180deg);
	}
	.el-icon--right {
	  margin-left: 5px; /* 调整间距 */
	  transition: transform 0.3s ease; /* 平滑的转换效果 */
	}
	
	.dropdown-tab{
		height: 60px!important;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-shrink: 0;
		background-color: #fff;
		>.role-class{
			margin-right: 20px;
			font-size: 18px;
		}
	}
	
	.dropdownclass{
		margin-right: 20px;
		font-weight: 600;
	}
	
	.logo-txt{
		height: 70px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 28px;
		font-weight: 700;
		color:#fff;
		background-color: transparent!important;
	}
	
	.side-menu {
	  width: 220px; /* 根据需要调整宽度 */
	  background-color: #fff; /* 背景颜色 */
	  /* 其他样式 */
	}
	
	.menu-item {
	  /* 菜单项样式 */
	}
	
	.menu-title {
	  padding: 10px; /* 调整间距 */
	  cursor: pointer;
	  /* 其他样式 */
	}
	
	.noChildmenuitem{
		display: flex;
		align-items: center;
		white-space: nowrap;
		overflow: hidden;
	}
	
	.layout {
	  height: 100%;
	  min-width: 800px;
	}
	
	.left-layout{
	  z-index:999!important;
	  overflow-x: hidden;
	}
	
	.img-icon889{
		width: 18px;
		height: 18px;
	}
	
	@media (max-width: 1366px) {

	}
	
	
</style>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  color: #333;
  // height: 100%;
}

.ivu-layout{
	background: #f3f8fc!important;
}



.ivu-menu-light {
  text-align: left;
  height: 100%;
}


.ivu-menu-item {
	font-size: 14px!important;
	color:#ccc!important;
}

.ivu-menu-item > span{
	margin-left: 10px;
}

.ivu-menu-submenu-title{
	font-size: 14px!important;
	color:#ccc!important;
}

.ivu-menu-item.menu-item-selected {
	  color: #fff!important; /* 白色字体 */
	  font-size: 15px!important; /* 增大字体尺寸 */
	  font-weight: 700!important;
	  background-color: transparent!important;
	  
 }

 .ivu-menu-item.sub-menu-item-selected {
	  background-color: #ffffff!important; 
	  color: #3662EC!important; 
 }
 
 .ivu-menu-submenu-title .submenu-selected{
   color: #fff !important; /* 白色字体 */
   font-size: 15px !important; /* 增大字体尺寸 */
   font-weight: 700!important;
 }
 
 .ivu-menu-item-active .ivu-menu-submenu-title-icon {
    color: #fff!important; 
    font-weight: 700; 
  }



.ivu-menu-submenu-title  > span{
	margin-left: 10px;
}

.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after {
    content: '';
    display: none;
    width: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
	 background-color: transparent!important;
}


.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item{
	display: flex!important;
	justify-content: center!important;
	padding-left: 0!important;
}



.el-dropdown-menu {
  width: 100px;
}


</style>
